import './Header.css'

import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import UseWindowDimensions from '../../Hooks/UseWindowDimensions.js';

import NavItem from '../NavItem/NavItem';
import Spacer from './../Spacer/Spacer.js'
import LanguageSelector from './../LanguageSelector/LanguageSelector.js'

export default function Header() {
    const currentPath = window.location.pathname;

    const mainPath = "/"
    const weddingsPath = "/bodes"
    const productsPath = "/carta"
    const galleryPath = "/galeria"
    const contactPath = "/nosaltres"

    const { t } = useTranslation();
    const { width } = UseWindowDimensions();

    if (width < 550) {
        return (
            <header>
                <nav className="navbar">
                    <img className="logo" src="images/logo.png" alt="logo gormanda mireia pastisseria" />
                    <Spacer />
                    <ul className="nav-list">
                        <NavItem 
                            title={t('navigationHome')}
                            active={mainPath === currentPath}
                            path={mainPath}
                        />
    
                        <NavItem 
                            title={t('navigationGallery')}
                            active={galleryPath === currentPath}
                            path={galleryPath}
                        />
    
                        <NavItem 
                            title={t('navigationAboutUs')}
                            active={contactPath === currentPath}
                            path={contactPath}
                        />
                        <LanguageSelector />
                    </ul>
                </nav>
                <Outlet />
            </header>
        )
    } else {
        return (
            <header>
                <nav className="navbar">
                    <img className="logo" src="images/logo.png" alt="logo gormanda mireia pastisseria" />
                    <Spacer />
                    <ul className="nav-list">
                        <NavItem 
                            title={t('navigationHome')}
                            active={mainPath === currentPath}
                            path={mainPath}
                        />
    
                        <NavItem 
                            title={t('navigationProducts')}
                            active={productsPath === currentPath}
                            path={productsPath}
                        />
    
                        <NavItem 
                            title={t('navigationGallery')}
                            active={galleryPath === currentPath}
                            path={galleryPath}
                        />
    
                        <NavItem 
                            title={t('navigationWeddings')}
                            active={weddingsPath === currentPath}
                            path={weddingsPath}
                        />
    
                        <NavItem 
                            title={t('navigationAboutUs')}
                            active={contactPath === currentPath}
                            path={contactPath}
                        />
                        <LanguageSelector />
                    </ul>
                </nav>
                <Outlet />
            </header>
        )
    }
  }
  