import './Gallery.css'
import '../../Styles/style.css'

import Header from '../../Components/Header/Header.js'
import Footer from '../../Components/Footer/Footer.js'
import PicturesGrid from '../../Components/PicturesGrid/PicturesGrid.js'

export default function Gallery() {
    return (
        <div className='Gallery'>
            <Header />
            
            <div className="first-section" >
                <PicturesGrid />
            </div>
            
            <Footer />
        </div>
    )
  }