import './CakeMenu.css'

import CakeIconItem from '../CakeIconItem/CakeIconItem.js'
import Title from '../Title/Title.js'

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function CakeMenu({ tag, title, description, seeAllButtonPath }) {
    const { t } = useTranslation();

    let button;

    if (seeAllButtonPath === null) {
        button = (<div />);
    } else {
        button = (
            <div className="see-all-products-container">
                <Link 
                    to={seeAllButtonPath}
                    className="button-primary button">
                        {t("cakeMenuButton")}
                </Link>
            </div>
        )
    }

    const cakeList = [
        {
            cakeTitle: t("cakeMenuAlmondOrnageCakeTitle"),
            cakeDescription: t("cakeMenuAlmondOrnageCakeDescription"),
            cakeImage: "https://www.feedmeichi.com/wp-content/uploads/2020/06/Orange-and-Almond.jpg",
            cakeTag: t("cakeMenuOrderTimeTag"),
            cakeIcon: "orange-almond.svg"
        },
        {
            cakeTitle: t("cakeMenuCarrotCakeTitle"),
            cakeDescription: t("cakeMenuCarrotCakeDescription"),
            cakeImage: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fall%2Fcarrot-cake.jpg?alt=media&token=74547ceb-1f4d-4878-b41a-4ce75b6f2309",
            cakeTag: t("cakeMenuOrderTimeTag"),
            cakeIcon: "carrot-cake.svg"
        },
        {
            cakeTitle: t("cakeMenuChocolateCakeTitle"),
            cakeDescription: t("cakeMenuChocolateCakeDescription"),
            cakeImage: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fall%2Fxocolate%20i%20toffee.jpg?alt=media&token=901b256c-400e-4fdf-91a1-0a377e8c060b",
            cakeTag: t("cakeMenuOrderTimeTag"),
            cakeIcon: "xocolate-toffee.svg"
        },
        {
            cakeTitle: t("cakeMenuOreoCakeTitle"),
            cakeDescription: t("cakeMenuOreoCakeDescription"),
            cakeImage: "https://m.media-amazon.com/images/I/61bXFXTKibL._AC_UF1000,1000_QL80_.jpg",
            cakeTag: t("cakeMenuOrderTimeTag"),
            cakeIcon: "oreo-cake.svg"
        },
        {
            cakeTitle: t("cakeMenuRedVelvetCakeTitle"),
            cakeDescription: t("cakeMenuRedVelvetCakeDescription"),
            cakeImage: "https://hips.hearstapps.com/hmg-prod/images/red-velvet-cake-index-651c24ca16ddb.jpg?crop=0.8887121362762642xw:1xh;center,top&resize=1200:*",
            cakeTag: t("cakeMenuOrderTimeTag"),
            cakeIcon: "red-velvet.svg"
        },
        {
            cakeTitle: t("cakeMenuLimeCakeTitle"),
            cakeDescription: t("cakeMenuLimeCakeDescription"),
            cakeImage: "https://static.independent.co.uk/2023/06/13/16/featureimage.jpg",
            cakeTag: t("cakeMenuOrderTimeTag"),
            cakeIcon: "mango-cardamom.svg"
        },
        {
            cakeTitle: t("cakeMenuMatchaTeaCakeTitle"),
            cakeDescription: t("cakeMenuMatchaTeaCakeDescription"),
            cakeImage: "https://sweetmouthjoy.com/wp-content/uploads/2021/08/easy-matcha-white-chocolate-cake-recipe.jpg",
            cakeTag: t("cakeMenuOrderTimeTag"),
            cakeIcon: "tea-matcha-chocolate.svg"
        },
        {
            cakeTitle: t("cakeMenuMousseCakeTitle"),
            cakeDescription: t("cakeMenuMousseCakeDescription"),
            cakeImage: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fall%2Fmousse-maduixa.jpg?alt=media&token=ca3c557b-fdf7-472f-82b5-3ee902711341",
            cakeTag: t("cakeMenuOrderTimeTag"),
            cakeIcon: "strawberry-mousse.svg"
        },
        {
            cakeTitle: t("cakeMenuStrawberryCreamCakeTitle"),
            cakeDescription: t("cakeMenuStrawberryCreamCakeDescription"),
            cakeImage: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fall%2Fnata-maduixes.jpg?alt=media&token=1bb72d3a-3d9b-4145-8ee1-7a83dca8c5ad",
            cakeTag: t("cakeMenuOrderTimeTag"),
            cakeIcon: "strawberry-cream.svg"
        },
    ]

    return (
        <section className="cakes-section" id="cakes-section-id">
            <div className="container">
                <Title 
                    tag={tag}
                    title={title}
                />
                <div className="description">
                    <p>
                        {description}
                    </p>
                </div>

                <div className="cake-list-container">
                    <ul id="cake-list" className='cake-list-container-grid'>
                        {
                            cakeList.map(function(cake){
                                return (
                                    <CakeIconItem 
                                        key={cake.cakeTitle}
                                        cakeTitle={cake.cakeTitle}
                                        cakeDescription={cake.cakeDescription}
                                        tag={cake.cakeTag}
                                        cakeIcon={cake.cakeIcon}
                                    />

                                    // <CakeItem 
                                    //     key={cake.cakeTitle}
                                    //     cakeTitle={cake.cakeTitle}
                                    //     cakeDescription={cake.cakeDescription}
                                    //     tag={cake.cakeTag}
                                    //     image={cake.cakeImage}
                                    // />
                                );
                            })
                        }
                    </ul>
                </div>

                {button}
            </div>
        </section>
    );
  }
  