import './Products.css'
import '../../Styles/style.css'

import Header from '../../Components/Header/Header.js'
import Footer from '../../Components/Footer/Footer.js'
import Reviews from '../../Components/Reviews/Reviews.js'
import CakeMenu from '../../Components/CakeMenu/CakeMenu.js'
import CakeCustomMenu from '../../Components/CakeCustomMenu/CakeCustomMenu.js'
import CakeEntrySection from '../../Components/CakeEntrySection/CakeEntrySection.js'

import { useTranslation } from 'react-i18next';

export default function Products() {
    const { t } = useTranslation();

    return (
        <div className='Products'>
            <Header />

            <div className="first-section" >
                <CakeEntrySection />
            </div>

            <CakeMenu 
                tag={t("productsCakeMenuSectionTag")}
                title={t("productsCakeMenuSectionTitle")}
                description={" "}
                seeAllButtonPath={null}
            />

            <CakeCustomMenu />

            <Reviews />

            <Footer />
        </div>
    )
  }