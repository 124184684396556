import './CakeEntrySection.css'

import React from 'react';
import Title from '../Title/Title.js'

import { useTranslation } from 'react-i18next';

export default function CakeEntrySection() {
    const { t } = useTranslation();

    return(
        <div className="cake-entry-section-container">
            <div className="container">
                <Title 
                    tag={t("productsCakeEntrySectionTag")}
                    title={t("productsCakeEntrySectionTitle")}
                />
                <div className="description">
                    <p>
                        {t("productsCakeEntrySectionDescription")}
                    </p>
                </div>

                <div className="cake-entry-section-products-container">
                    <div className="cake-entry-section-products-container-item">
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/products-main%2FIMG_7525.jpg?alt=media&token=4e7b02f6-f547-4251-b0c7-f49d4158be32" 
                            alt={t("productsCakeEntrySectionItemCelebrations")}
                        />
                        <h2>{t("productsCakeEntrySectionItemCelebrations")}</h2>
                    </div>

                    <div className="cake-entry-section-products-container-item">
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/products-main%2FIMG_7601.jpg?alt=media&token=02fab530-674a-4cee-bad5-1c9797e9aa5b" 
                            alt={t("productsCakeEntrySectionItemSpecialDate")}
                        />
                        <h2>{t("productsCakeEntrySectionItemSpecialDate")}</h2>
                    </div>

                    <div className="cake-entry-section-products-container-item">
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/products-main%2FIMG_1883.jpg?alt=media&token=b7af584d-920e-4bc5-bdb8-e660a0835c2d" 
                            alt={t("productsCakeEntrySectionItemCustom")}
                        />
                        <h2>{t("productsCakeEntrySectionItemCustom")}</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}