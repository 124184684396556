import './PicturesGrid.css'

import React, { createRef } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";

export default class PicturesGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            cakes: []
        };
        this.galleryRef = createRef();
    }

    componentDidMount() {
        this.loadCakeGallery();
    }

    loadCakeGallery = () => {
        const database = getDatabase();
        const imagesReference = ref(database, '/cakeGallery');
        
        onValue(imagesReference, (snapshot) => {
            this.setState( { cakes: snapshot.val() } );
        });
    }

    render() {
        if (this.state.cakes.length > 0) {
            return (
                <div className="gallery-images-section">
                    <div className="container">
                        <div ref={this.galleryRef} className="gallery">
                        {this.state.cakes.map((cakeImageObject, index) => (
                            <div key={index} className="gallery-item">
                                <img src={cakeImageObject.src} alt={cakeImageObject.alt} />
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
              );
        } else {
            return(
                <div ref={this.galleryRef} className="gallery" />
            );
        }
    }
}