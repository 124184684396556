import './LanguageSelector.css'

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';

export default function LanguageSelector() {
    const languageDetector = new LanguageDetector();
    languageDetector.addDetector({
        name: 'cookie',
        lookup(options) {
            return Cookies.get('lang');
        },
    });
    
    function handleChangeLanguage(event) {
        const newLang = event.target.value;
        Cookies.set('lang', newLang);
        i18n.changeLanguage(newLang);
    }

    function returningSomething() {
        var language = Cookies.get('lang');

        if (language === "cat") {
            return "CAT.svg"
        } else if (language === "en") {
            return "ENG.svg"
        } else if (language === "es") {
            return "SPN.svg"
        }

        return "CAT.svg";
    }

    const languages = [
        {
            key: "cat",
            name: "Català",
            icon: "CAT.svg"
        },
        {
            key: "en",
            name: "English",
            icon: "ENG.svg"
        },
        {
            key: "es",
            name: "Español",
            icon: "SPN.svg"
        }
    ]

    var currentLanguage = Cookies.get('lang');

    if (currentLanguage == undefined) {
        const navigatorLanguage = (navigator.language || navigator.userLanguage).split("-")[0];
        Cookies.set('lang', navigatorLanguage);
        currentLanguage = navigatorLanguage
    }

    console.log("Language: " + currentLanguage);

    return (
        <div className="change-language-container">
            <div className="image-selector-overlapping">
                <img alt={currentLanguage} src={"../images/flags/" + returningSomething()}/>
                <select defaultValue={currentLanguage} className="custom-select" onChange={handleChangeLanguage}>
                    {
                        languages.map(function(language){
                            return (
                                <option 
                                    key={language.key} 
                                    className={language.key} 
                                    value={language.key}>{language.name}
                                </option>
                            );
                        })
                    }
                </select>
            </div>
        </div>
    )
}
  