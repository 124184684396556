import './CakeCustomMenu.css'

import Title from '../Title/Title.js'
import CakeCustomItem from '../CakeCustomItem/CakeCustomItem.js'

import { useTranslation } from 'react-i18next';

export default function CakeCustomMenu() {
    const { t } = useTranslation();

    return (
        <section className="cakes-custom-section" id="cakes-custom-section-id">
            <div className="container">
                <Title 
                    tag={t("productsCakeMenuCustomSectionTag")}
                    title={t("productsCakeMenuCustomSectionTitle")}
                />

                <div className="steps-container">
                    <div className="step-image-text-container">
                        <img 
                            className="step-image" 
                            alt="" 
                            src="../images/icons/numbers/one.svg"
                        />
                        <p className="step-text">
                            Escull la base
                        </p>
                    </div>

                    <div className="step-image-text-container">
                        <img 
                            className="step-image"
                            alt="" 
                            src="../images/icons/numbers/two.svg"
                        />
                        <p className="step-text">
                            Escull el gust
                        </p>
                    </div>

                    <div className="step-image-text-container">
                        <img 
                            className="step-image" 
                            alt="" 
                            src="../images/icons/numbers/three.svg"
                        />
                        <p className="step-text">
                            Escull la decoració
                        </p>
                    </div>
                </div>

                <div className="custom-cake-items-list">
                    <CakeCustomItem 
                        title={"Pastís lleuger"}
                        titleOptions={[]}
                        flavours={["Nata", "Trufa", "Crema cremada", "St. Marc"]}
                        decorations={["Temàtica", "Fotografia", "Personalitzat", "Senzill", "Flors naturals", "Fruita fresca"]}
                    />
                    <CakeCustomItem 
                        title={"Pastís de vainilla o xocolata"}
                        titleOptions={[]}
                        flavours={["Ganaché de xocolata negra", "Ganaché de xocolata blanca", "Ganaché de xocolata llet", "Acabat amb crema de llimona, oreo, mango, …", "Gianduja (xocolata i crema de fruit secs)"]}
                        decorations={["Temàtica", "Fotografia", "Personalitzat", "Senzill", "Flors naturals", "Fruita fresca"]}
                    />
                    <CakeCustomItem 
                        title={"Tipus tartaleta de galeta"}
                        titleOptions={[]}
                        flavours={["Lemon pie", "Crema pastissera i fruita"]}
                        decorations={["Personalitzat", "Senzill", "Fruita fresca"]}
                    />
                    <CakeCustomItem 
                        title={"Pasta de full"}
                        titleOptions={["Tipus tortell", "Tipus banda"]}
                        flavours={["Crema", "Nata", "Trufa"]}
                        decorations={["Senzill", "Fruita fresca"]}
                    />
                </div>
            </div>
        </section>
    );
}